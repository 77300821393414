@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');

*{
    font-family: 'Poppins', 'Inter', sans-serif;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    scroll-behavior: smooth;
}
html{
    min-height: 100vh;
}
ul{
    list-style: none;
}
a{
    text-decoration: none;
}
body{
    margin: 0px;
    padding: 0px;
    font-family: 'Rubik', sans-serif;
    background: linear-gradient(180.09deg, #162660 -10.43%, #1E74B1 19.57%, #296acd 47.66%, #1E74B1 74.16%, #1E3E6E 103.48%);
    background-repeat: no-repeat;
}